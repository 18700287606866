<template>
  <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_0_3)">
      <mask id="mask0_0_3" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="150" height="150">
        <path d="M110 0H40C17.9086 0 0 17.9086 0 40V110C0 132.091 17.9086 150 40 150H110C132.091 150 150 132.091 150 110V40C150 17.9086 132.091 0 110 0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_0_3)">
        <path d="M110 0H40C17.9086 0 0 17.9086 0 40V110C0 132.091 17.9086 150 40 150H110C132.091 150 150 132.091 150 110V40C150 17.9086 132.091 0 110 0Z" fill="#FFF13C"/>
        <path d="M121.076 78.2639C118.436 75.6186 115.082 74.296 110.997 74.296C108.357 74.296 106.003 74.9215 103.88 76.1727C101.775 77.4238 100.098 79.0682 98.8497 81.1237C97.6011 83.1613 96.9768 85.5027 96.9768 88.148C96.9768 91.9729 98.3146 95.2438 101.026 97.9427C103.737 100.642 107.037 102 110.997 102C113.619 102 116.028 101.374 118.204 100.123C120.362 98.8721 122.039 97.1563 123.234 94.9757C124.411 92.7951 125 90.5252 125 88.148C125 84.1801 123.698 80.8913 121.058 78.2639H121.076ZM120.273 61.6594L123.27 -34H98.7248L101.918 61.6594H120.273ZM87.8335 -0.612167H61.5762L21.7086 44.465V-0.612167H0V99.462H21.7086V51.5608L64.5551 99.462H92.5784L44.4341 47.2175L87.8513 -0.612167H87.8335Z" fill="#1F1E1E"/>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_0_3">
        <rect width="150" height="150" fill="white"/>
      </clipPath>
    </defs>
  </svg>

</template>
